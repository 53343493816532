.song {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  text-align: center;
  background: #191919;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  padding: .3em;
}
.song > section {
  flex: 0 0 calc(100% / 3);
}
.song .leftSide {
  display: flex;
  align-items: center;
  gap: .5em;
}
.song .leftSide .cover {
  height: 70px;
  width: 70px;
}
.song .leftSide .cover img {
  height: inherit;
  width: inherit;
  object-fit: cover;
  border-radius: 3px;
}
.songInfo {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}
@media screen and (max-width: 768px) {
  .song {
    display: block;
    text-align: left;
    padding: 1.5rem;
  }
  .song .leftSide {
    margin-bottom: 1em;
  }
  .song .leftSide .cover {
    height: 70px;
    width: 70px;
  }
  .song .middle {
    font-weight: bold;
  }
  .song .addedDate {
    color: var(--muted-text)
  }
}