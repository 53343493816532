.searchBar {
  margin: 0 auto 2rem;
  max-width: 700px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}
.searchBar input {
  background: var(--dark-color);
  outline: none;
  border: 1px solid #3f3f3f;
  color: #fff;
  padding: 1em;
  width: 100%;
  border-radius: 5px;
  font-size: 1.1em;
}
.searchBar .icon {
  position: absolute;
  right: 15px;
  font-size: 1.5em;
}