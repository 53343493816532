.title {
  color: #fff;
  font-size: 2.5rem;
}
.title::after {
  content: "";
  width: 120px;
  height: 5px;
  background: var(--purple-color);
  display: block;
  margin-top: 0.2em;
  border-radius: 30px;
  margin-bottom: .5em;
}
.playlistContainerContent {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2em;
  margin-bottom: 3em;
}