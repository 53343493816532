.loginPage {
  height: 100vh;
  width: 100vw;
  background: linear-gradient(var(--primary-color), var(--dark-color));
  overflow: scroll;
}
.pageBody {
  padding: 0 1em;
}
.loginPage header {
  background: var(--dark-color);
  padding: 1em;
  letter-spacing: 2px;
}
.loginPage header a {
  color: #fff;
}
.loginContainer {
  max-width: 520px;
  width: 100%;
  margin: 3em auto 1em;
  min-height: 550px;
  background: #000;
  padding: 2.5em 1.5em;
  border-radius: 8px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.loginContainer .title {
  text-align: center;
  text-transform: uppercase;
}
.loginContainer .title::after {
  content: "";
  width: 120px;
  height: 5px;
  background: var(--purple-color);
  display: block;
  border-radius: 30px;
  margin: .4em auto;
}
.loginContainer .inputs {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
}
.loginContainer .link {
  display: flex;
  gap: .3em;
  justify-content: center;
  margin-top: 2em;
}
.loginContainer .link a {
  color: var(--aqua-color);
  transition: all 0.2s;
  font-weight: bold;
}
.loginContainer .link a:hover {
  color: var(--dark-aqua-color);
}
.loginContainer .submitButton {
  width: 100%;
  padding: 1em;
  cursor: pointer;
  margin-top: 3em;
  background: var(--purple-color);
  color: #fff;
  outline: none;
  border: 0;
  transition: all 0.3s;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: .9rem;
}
.loginContainer .submitButton:hover {
  background: var(--dark-purple-color);
}
.error {
  background: rgba(252, 27, 27, 0.424);
  padding: 1em;
  border-radius: 5px;
  font-size: 1.1em;
  margin: 1.5em 0 0
}