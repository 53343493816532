.footer {
  height: 90px;
  background: var(--dark-color);
  padding: 0 2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer audio {
  display: none;
}
.footer > div {
  flex: 1 1 0px;
  display: flex;
}
.footer > div:nth-child(2) {
  justify-content: center;
}
.footer > div:nth-child(2) > div {
  cursor: pointer;
}
.footer > div:last-child {
  justify-content: right;
}
.footer .icon {
  background: rgb(62, 62, 62);
  height: 70px;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  border-radius: 2px;
  margin-right: .3em;
}
.footer .icon img {
  width: inherit;
  height: inherit;
  object-fit: cover;
}
.footer .musicInfo {
  display: flex;
  align-items: center;
}
.footer .musicInfo .details .songName {
  font-size: 1.1em;
  margin-bottom: .2em;
}
.footer .musicInfo .details .artistName {
  font-size: 0.8rem;
  color: var(--muted-text);
}
.footer .controls {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer .controls .controlButtons {
  display: flex;
  font-size: 2.2rem;
}
.footer .controls .progressBar,
.footer .controls .progressBar progress {
  width: 100%;
  height: 20px;
}
.footer .controls .progressBar input {
  height: 10px;
  accent-color: var(--purple-color);
  width: 100%;
  border: 0;
  outline: 0;
  background: #303030;
  cursor: pointer;
  border-radius: 5px;
}
.footer .volumeControls {
  display: flex;
  align-items: center;
  gap: 1em;
}
.footer .volumeControls .volumeIcon svg {
  font-size: 1.5em;
}
.footer .volumeControls input {
  accent-color: var(--aqua-color);
}
@media screen and (max-width: 768px) {
  .footer {
    padding: 0 1em;
    height: 120px;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: auto auto;
  }
  .footer .volumeControls {
    display: flex;
    justify-content: center !important;
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .footer .volumeControls input {
    width: 100%;
  }
}