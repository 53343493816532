.formInput {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
}
.formInput label {
  font-size: 1.1rem;
}
.formInput input {
  background: var(--dark-color);
  outline: none;
  border: 1px solid #3f3f3f;
  color: #fff;
  padding: .7em;
  width: 100%;
  border-radius: 5px;
  font-size: 1.1em;
  margin-top: .7em;
}