.flexContainer {
    display: flex;
}
.header {
    background: var(--dark-color);
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.5em;
}
.header .links {
    display: flex;
    gap: 1em;
}
.header .links button {
    color: #fff;
    background: var(--aqua-color);
    text-decoration: none;
    padding: .5em 2em;
    border-radius: 30px;
    transition: all 0.3s;
    outline: none;
    border: 0;
    cursor: pointer;
}
.header .links button:hover {
    background: var(--dark-aqua-color);
}
.header .title {
    text-transform: uppercase;
}
.pageContent {
    width: 100%;
    overflow: scroll;
}
.albumsContainer {
    padding: 2.5em;
    display: flex;
    flex-direction: column;
    height: calc(100vh - (50px + 90px));
}
@media screen and (max-width: 768px) {
    .albumsContainer {
        padding: 0.5em;
        height: calc(100vh - (50px + 120px));
    }
}