.playlistInfo {
  display: flex;
  align-items: center;
  gap: 1.2em;
  height: 250px;
}
.playlistInfo img {
  height: inherit;
  width: 250px;
  object-fit: cover;
  border-radius: 5px;
}
.playlistInfo .text .mutedText {
  color: var(--muted-text);
  margin-bottom: .3em;
}
.playlistInfo .text .name {
  font-size: 3rem;
  margin-bottom: 1rem;
}
.playlistInfo .plays {
  display: flex;
  align-items: center;
  gap: .3em;
}
.playlistInfo .plays .playsContainerIcon {
  display: flex;
  align-items: center;
}
.songs {
  background: var(--dark-color);
  margin: 3em 0;
  padding: 2em;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.songs .title::after {
  content: "";
  width: 70px;
  height: 5px;
  background: var(--purple-color);
  display: block;
  margin-top: 0.4em;
  border-radius: 30px;
  margin-bottom: .5em;
}
.error {
  font-size: 1.3rem;
  text-align: center;
  background: rgba(255, 0, 0, 0.622);
  padding: .7em;
  border-radius: 5px;
}
.playButton {
  margin-top: 1em;
  background: var(--purple-color);
  height: 40px;
  max-width: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .3em;
  border-radius: 30px;
  font-size: 1.2em;
  cursor: pointer;
  transition: all .2s;
}
.playButton:hover {
  background: var(--dark-purple-color)
}
@media screen and (max-width: 768px) {
  .playlistInfo {
    display: block;
    margin-bottom: 6em;
    margin: 1em auto 0;
    text-align: center;
    height: auto;
  }
  .text {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}